import React from 'react';
import { Globals, Breakpoints } from '../styles/global';
import { Link, graphql } from 'gatsby';
import Layout from '../layouts';
import showdown from 'showdown';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  OrangeBox,
  Inner,
  WhiteBox,
  PureWhiteBox,
  DarkGreyBox,
  DarkGreyNoYBox,
  BlueBox,
  TransparentBox,
  FaqBox,
  ImgResponsive,
} from '../components/shared';
import { Modal, Button, Box } from '@material-ui/core';
import { ColorStrip } from '../components/color-strip';
import { ModalBody } from '../components/modal';
import SEO from '../components/seo';

const converter = new showdown.Converter();

const Hero = styled(DarkGreyBox)`
  h2 {
    ${Breakpoints.sm`
      max-width: 40%;
    `}
  }
`;
const Stethoscope = styled.div`
  position: relative;
  &::after {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    background-image: url(/system-of-care/stethoscope.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    top: 0;
    bottom: 0;
    ${Breakpoints.sm`
    content: '';
  `}
  }
`;

const SquareGrid = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
  & > ${Inner} {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    ${Breakpoints.sm`
      > div  {
        width: 50%;
      }
    `}
  }

  h4,
  p,
  a {
    position: relative;
    z-index: 4;
  }
`;

const LineGrid = styled.div`
  ${Breakpoints.sm`
  display: flex;
  justify-content: space-between;
  > section {
    width: calc(25% - 1rem);
  }
  `}
`;

const PartnersRow = styled.div`
  grid-column-gap: 2rem;
  display: flex;
  justify-content: center;
  & div {
    width: 10rem;
  }
  button {
    display: flex;
    appearance: none;
    background-color: transparent;
    outline: none;
    border: none;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 100%;
    display: block;
  }
`;

interface IFCMA {
  fcmaTitle: string;
  fcmaURL: string;
  fcmaCopy: string;
}

const Body = (props: { selected: number; copy: IFCMA[] }) => {
  const { copy, selected } = props;
  const { fcmaTitle, fcmaURL, fcmaCopy } = copy[selected - 1];
  return (
    <>
      <h3>
        <a href={fcmaURL}>{fcmaTitle}</a>
      </h3>

      <div
        className="markdown-holder"
        dangerouslySetInnerHTML={{ __html: converter.makeHtml(fcmaCopy) }}
      />
    </>
  );
};

const SystemPageTemplate = ({
  title,
  tagline,
  heroRight,
  heroLeft,
  squareTitle1,
  squareContent1,
  squareLink1,
  squareTitle2,
  squareContent2,
  squareLink2,
  squareTitle3,
  squareContent3,
  squareLink3,
  squareTitle4,
  squareContent4,
  squareLink4,
  helpTitle,
  helpTitle1,
  helpContent1,
  helpLink1,
  helpTitle2,
  helpContent2,
  helpLink2,
  helpTitle3,
  helpContent3,
  helpLink3,
  helpTitle4,
  helpContent4,
  helpLink4,
  tyTitle,
  tyContent,
  fcTitle,
  fcContent,
  resourcesTitle,
  fcma,
}: {
  title: string;
  tagline: string;
  heroRight: string;
  heroLeft: string;
  squareTitle1: string;
  squareContent1: string;
  squareLink1: string;
  squareTitle2: string;
  squareContent2: string;
  squareLink2: string;
  squareTitle3: string;
  squareContent3: string;
  squareLink3: string;
  squareTitle4: string;
  squareContent4: string;
  squareLink4: string;
  helpTitle: string;
  helpTitle1: string;
  helpContent1: string;
  helpLink1: string;
  helpTitle2: string;
  helpContent2: string;
  helpLink2: string;
  helpTitle3: string;
  helpContent3: string;
  helpLink3: string;
  helpTitle4: string;
  helpContent4: string;
  helpLink4: string;
  tyTitle: string;
  tyContent: string;
  fcTitle: string;
  fcContent: string;
  resourcesTitle: string;
  fcma: IFCMA[];
}) => {
  const [open, setOpen] = React.useState(0);
  return (
    <>
      <SEO title={title} />
      <Hero>
        <Inner justifyContent="space-between" display="flex">
          <div>
            <h1 className="font-u3">{title}</h1>
            <q>{tagline}</q>
          </div>
          <h2>{heroRight}</h2>
        </Inner>
      </Hero>
      <Stethoscope>
        <SquareGrid className="first-square">
          <Inner>
            <TransparentBox>
              <Box>
                <h4 className="font-u2">{squareTitle1}</h4>
                <p className="font-b">{squareContent1}</p>
                <a href={squareLink1} className="button white-button">
                  View All
                </a>
              </Box>
            </TransparentBox>
            <TransparentBox className="font-orange">
              <Box padding={[0, '0 2rem']}>
                <h4 className="font-u2">{squareTitle2}</h4>
                <p className="font-b">{squareContent2}</p>
                <a href={squareLink2} className="button orange-button">
                  View All
                </a>
              </Box>
            </TransparentBox>
          </Inner>
        </SquareGrid>
        <SquareGrid className="last-square">
          <Inner>
            <TransparentBox className="font-orange">
              <Box>
                <h4 className="font-u2">{squareTitle3}</h4>
                <p className="font-b">{squareContent3}</p>
                <a href={squareLink3} className="button orange-button">
                  {' '}
                  More
                </a>
              </Box>
            </TransparentBox>
            <TransparentBox className="font-white">
              <Box padding={[0, '0 2rem']}>
                <h4 className="font-u2">{squareTitle4}</h4>
                <p className="font-b">{squareContent4}</p>
                <a href={squareLink4} className="button purple-button">
                  {' '}
                  More
                </a>
              </Box>
            </TransparentBox>
          </Inner>
        </SquareGrid>
      </Stethoscope>
      <WhiteBox>
        <Inner>
          <h4 className="font-u2 text-center">{helpTitle}</h4>
          <LineGrid>
            <section>
              <h4 className="font-u1">{helpTitle1}</h4>
              <p className="font-b">{helpContent1}</p>
              <a href={helpLink1} className="button red-icon">
                Learn More{' '}
                <span>
                  <ArrowForwardIcon />
                </span>
              </a>
            </section>
            <section>
              <h4 className="font-u1">{helpTitle2}</h4>
              <p className="font-b">{helpContent2}</p>
              <a href={helpLink2} className="button yellow-icon">
                Learn More{' '}
                <span>
                  <ArrowForwardIcon />
                </span>
              </a>
            </section>
            <section>
              <h4 className="font-u1">{helpTitle3}</h4>
              <p className="font-b">{helpContent3}</p>
              <a href={helpLink3} className="button green-icon">
                Learn More{' '}
                <span>
                  <ArrowForwardIcon />
                </span>
              </a>
            </section>
            <section>
              <h4 className="font-u1">{helpTitle4}</h4>
              <p className="font-b">{helpContent4}</p>
              <a href={helpLink4} className="button purple-icon">
                Learn More{' '}
                <span>
                  <ArrowForwardIcon />
                </span>
              </a>
            </section>
          </LineGrid>
        </Inner>
      </WhiteBox>
      <ColorStrip height="1rem" />
      <DarkGreyNoYBox>
        <Inner display={['block', 'flex']} justifyContent="space-between">
          <Box
            width={['100%', '50%']}
            marginRight={['0', '2rem']}
            className="py-u2"
          >
            <h4 className="font-u1">{tyTitle}</h4>

            <div
              className="markdown-holder-dark"
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(tyContent),
              }}
            />
          </Box>
          <Box width={['100%', '50%']}>
            <ImgResponsive src="/skater.jpg" alt="" />
          </Box>
        </Inner>
      </DarkGreyNoYBox>
      <PureWhiteBox>
        <Inner>
          <h4 className="font-u3 text-center">{fcTitle}</h4>
          <p className="font-b text-center">{fcContent}</p>
          <h5 className="font-u1 mb-u1 uppercase text-center">
            Full Case Management Agencies
          </h5>
          <PartnersRow>
            <button onClick={() => setOpen(1)}>
              <div>
                <img src="/system-of-care/centerforfamilyandchild.png" />
              </div>
            </button>
            <button onClick={() => setOpen(2)}>
              <div>
                <img src="/system-of-care/chs.png" />
              </div>
            </button>
            <button onClick={() => setOpen(3)}>
              <div>
                <img src="/system-of-care/familyresource.png" />
              </div>
            </button>
            <button onClick={() => setOpen(4)}>
              <div>
                <img src="/system-of-care/weslethouse.png" />
              </div>
            </button>
          </PartnersRow>
          <h5 className="font-u1 mb-u1 uppercase text-center">
            Foster and Adoptive Parent Associations
          </h5>
          <PartnersRow>
            <button onClick={() => setOpen(5)}>
              <div>
                <img src="/system-of-care/hope4kids.png" />
              </div>
            </button>
            <button onClick={() => setOpen(6)}>
              <div>
                <img src="/system-of-care/foster&adoptiveparent.png" />
              </div>
            </button>
            <button onClick={() => setOpen(7)}>
              <div>
                <img src="/system-of-care/foster-parent-association.png" />
              </div>
            </button>
          </PartnersRow>
          <Box className="text-center" my={[6]}>
            <Link
              to="/system-of-care/partners"
              className="button button-lg green-button"
            >
              Learn More
            </Link>
          </Box>
        </Inner>
      </PureWhiteBox>
      <FaqBox
        url="/faq/"
        bgColor={Globals.purple}
        color={Globals.white}
        linkColor={Globals.white}
      />
      <Modal
        open={open > 0}
        onClose={() => {
          setOpen(0);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <ModalBody>
            <Body selected={open} copy={fcma} />
          </ModalBody>
        </div>
      </Modal>
      <WhiteBox>
        <Inner>
          <h4 className="font-u1">
            <Link to="/system-of-care/resources">{resourcesTitle}</Link>
          </h4>
        </Inner>
      </WhiteBox>
    </>
  );
};

const SOCPage = ({
  data: {
    markdownRemark: {
      frontmatter: {
        title = '',
        tagline = '',
        heroRight = '',
        heroLeft = '',
        squareTitle1 = '',
        squareContent1 = '',
        squareLink1 = '',
        squareTitle2 = '',
        squareContent2 = '',
        squareLink2 = '',
        squareTitle3 = '',
        squareContent3 = '',
        squareLink3 = '',
        squareTitle4 = '',
        squareContent4 = '',
        squareLink4 = '',
        helpTitle = '',
        helpTitle1 = '',
        helpContent1 = '',
        helpLink1 = '',
        helpTitle2 = '',
        helpContent2 = '',
        helpLink2 = '',
        helpTitle3 = '',
        helpContent3 = '',
        helpLink3 = '',
        helpTitle4 = '',
        helpContent4 = '',
        helpLink4 = '',
        tyTitle = '',
        tyContent = '',
        fcTitle = '',
        fcContent = '',
        resourcesTitle = '',
        fcma = [],
      },
    },
  },
}) => {
  return (
    <Layout>
      <SystemPageTemplate
        title={title}
        tagline={tagline}
        heroRight={heroRight}
        heroLeft={heroLeft}
        squareTitle1={squareTitle1}
        squareContent1={squareContent1}
        squareLink1={squareLink1}
        squareTitle2={squareTitle2}
        squareContent2={squareContent2}
        squareLink2={squareLink2}
        squareTitle3={squareTitle3}
        squareContent3={squareContent3}
        squareLink3={squareLink3}
        squareTitle4={squareTitle4}
        squareContent4={squareContent4}
        squareLink4={squareLink4}
        helpTitle={helpTitle}
        helpTitle1={helpTitle1}
        helpContent1={helpContent1}
        helpLink1={helpLink1}
        helpTitle2={helpTitle2}
        helpContent2={helpContent2}
        helpLink2={helpLink2}
        helpTitle3={helpTitle3}
        helpContent3={helpContent3}
        helpLink3={helpLink3}
        helpTitle4={helpTitle4}
        helpContent4={helpContent4}
        helpLink4={helpLink4}
        tyTitle={tyTitle}
        tyContent={tyContent}
        fcTitle={fcTitle}
        fcContent={fcContent}
        resourcesTitle={resourcesTitle}
        fcma={fcma}
      />
    </Layout>
  );
};

export default SOCPage;

export const SystemOfCarePageQuery = graphql`
  query SystemOfCarePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        title
        tagline
        heroRight
        heroLeft
        squareTitle1
        squareContent1
        squareLink1
        squareTitle2
        squareContent2
        squareLink2
        squareTitle3
        squareContent3
        squareLink3
        squareTitle4
        squareContent4
        squareLink4
        helpTitle
        helpTitle1
        helpContent1
        helpLink1
        helpTitle2
        helpContent2
        helpLink2
        helpTitle3
        helpContent3
        helpLink3
        helpTitle4
        helpContent4
        helpLink4
        tyTitle
        tyContent
        fcTitle
        fcContent
        resourcesTitle
        fcma {
          fcmaTitle
          fcmaURL
          fcmaCopy
        }
      }
    }
  }
`;
